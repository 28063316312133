html, body {
  width:100%;
	height:100%;
	/* position:absolute;
  left:0%;
  top:0px; */
  /* background:#1E1E20 url("./img/agri-india.jpeg") center top no-repeat; */
  background:#1d201d url("./img/agri-india-3.jpg") center top no-repeat;
  background-size: cover;  
}

.App {
  text-align: center;
}

header {
  font-family: 'Fascinate Inline', cursive;
  font-size: 40px;
  padding: 20px 20px 0px 20px;
  font-weight: bold;
  color: #000000;
  text-shadow: 2px 2px 4px #ffffff;
  /* color: #419432; */
}

.main_main {
  margin: 20px;
  font-family: cursive;
  font-size: 1.1em;
  /* display: flex;
  justify-content: center; */
  color: #ffffff;
}

.main_main ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  display: flex;
  justify-content: center;  
}

.main_main ul li {
  /* float: left; */
  border-right: 1px solid #bbb;
}

.main_main ul li:last-child {
  border-right: none;
}

.main_main ul li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.main_main ul li a:hover {
  background-color: #111;
}

.content {
  /* display: flex;
  justify-content: center; */
  display: grid;  
  grid-template-columns: 89% 10%;
  /* text-align: justify;   */
}

/* .text_content {
  position: absolute;
  left: 10px;
  width: 90%;
} */

.sidebar_menu {
  display: flex;
  justify-content: center;
  /* position: absolute;
  right: 10px; */
  float: right;
  margin: 20px 0px 0px 0px;
  font-family: cursive;
  font-size: 1.4em;  
  color: #ffffff;
}

/* .commodity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  text-justify: inter-word;
  color: #ffffff;
  background: rgba(16, 85, 23, 0.5);
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
  margin: 20px;
  font-family: 'Gudea', sans-serif;
}

.commodity h2 {
  text-align: center;
}

.commodity th {
  font-weight: bolder;
  color: yellow;
  padding: 5px;
  text-align: center;
}

.commodity tr {
  box-shadow: 5px 10px 15px rgba(200, 216, 200, 0.1);
}
.commodity td {
  padding: 8px;
  text-align: center;
} */

.commodity {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;  
}

.commodity h2 {
  color: yellow;
}

.grid-container-header {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  padding: 10px;
  font-weight: bolder;
  color: yellowgreen;
  background-color: rgba(94, 86, 86, 0.5);
}

.grid-container-header input {
  padding: 8px 10px 5px 10px;
  border-radius: 5px;
  font-size: 15px;
  border: 2px solid yellow;
}

.grid-container-header input:focus {
  border: 3px solid red;
}

.grid-container-header input::placeholder{
  color: black;
  font-weight: bold;
}

.grid-container {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  padding: 10px;
  color: #ffffff;
}

.grid-container:hover {
  background-color: rgba(225, 255, 0, 0.8);
  color: black;
}

.grid-item {
  box-shadow: 5px 10px 15px rgba(200, 216, 200, 0.1);
  padding: 8px;
  text-align: center;  
}